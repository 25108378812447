import React, { useState, useEffect } from 'react';
import { analyzeJournalEntry, JournalEntry, loadJournalEntries, createJournalEntry } from '../services/cloudflareAI';
import { FaPlus, FaHistory } from 'react-icons/fa';

interface Analysis {
  insights: string[];
  scripture: string;
  nextStep: string;
}

const Journal: React.FC = () => {
  const [journalContent, setJournalContent] = useState('');
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [entries, setEntries] = useState<JournalEntry[]>([]);
  const [selectedEntry, setSelectedEntry] = useState<JournalEntry | null>(null);

  useEffect(() => {
    // Load journal entries on component mount
    const savedEntries = loadJournalEntries();
    setEntries(savedEntries);
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!journalContent.trim()) return;

    setIsAnalyzing(true);
    try {
      const newEntry = await createJournalEntry(journalContent);
      setEntries(prev => [newEntry, ...prev]);
      setSelectedEntry(newEntry);
      setJournalContent('');
    } catch (error) {
      console.error('Error creating journal entry:', error);
      alert('Failed to analyze journal entry. Please try again.');
    } finally {
      setIsAnalyzing(false);
    }
  };

  const handleNewEntry = () => {
    setSelectedEntry(null);
    setJournalContent('');
  };

  return (
    <div className="flex h-full">
      {/* Journal History Sidebar */}
      <div className={`w-64 bg-gray-100 p-4 border-r ${showHistory ? '' : 'hidden'}`}>
        <div className="mb-4 flex justify-between items-center">
          <h2 className="text-lg font-semibold">Journal History</h2>
          <button onClick={() => setShowHistory(false)} className="text-gray-500 hover:text-gray-700">
            <span className="sr-only">Close history</span>
            ×
          </button>
        </div>
        <div className="space-y-2">
          {entries.map(entry => (
            <button
              key={entry.id}
              onClick={() => setSelectedEntry(entry)}
              className={`w-full text-left p-2 rounded hover:bg-gray-200 ${
                selectedEntry?.id === entry.id ? 'bg-gray-200' : ''
              }`}
            >
              <div className="font-medium truncate">{entry.content.slice(0, 30)}...</div>
              <div className="text-xs text-gray-500">
                {new Date(entry.createdAt).toLocaleDateString()}
              </div>
            </button>
          ))}
        </div>
      </div>

      {/* Main Journal Area */}
      <div className="flex-1 flex flex-col h-full">
        {/* Header */}
        <div className="p-4 border-b flex justify-between items-center">
          <div className="flex space-x-4">
            <button
              onClick={handleNewEntry}
              className="flex items-center space-x-2 text-blue-600 hover:text-blue-800"
            >
              <FaPlus />
              <span>New Entry</span>
            </button>
            <button
              onClick={() => setShowHistory(!showHistory)}
              className="flex items-center space-x-2 text-blue-600 hover:text-blue-800"
            >
              <FaHistory />
              <span>History</span>
            </button>
          </div>
        </div>

        {/* Content Area */}
        <div className="flex-1 overflow-auto p-4">
          {selectedEntry ? (
            // View existing entry
            <div className="space-y-4">
              <div className="bg-white p-4 rounded-lg shadow">
                <div className="text-sm text-gray-500 mb-2">
                  {new Date(selectedEntry.createdAt).toLocaleString()}
                </div>
                <div className="whitespace-pre-wrap">{selectedEntry.content}</div>
              </div>
              <div className="bg-white p-4 rounded-lg shadow space-y-4">
                <h3 className="font-semibold">Analysis</h3>
                <div>
                  <h4 className="text-sm font-medium text-gray-700">Insight</h4>
                  <ul className="list-disc list-inside">
                    {selectedEntry.analysis.insights.map((insight, i) => (
                      <li key={i}>{insight}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h4 className="text-sm font-medium text-gray-700">Scripture</h4>
                  <p>{selectedEntry.analysis.scripture}</p>
                </div>
                <div>
                  <h4 className="text-sm font-medium text-gray-700">Next Step</h4>
                  <p>{selectedEntry.analysis.nextStep}</p>
                </div>
              </div>
            </div>
          ) : (
            // New entry form
            <form onSubmit={handleSubmit} className="h-full flex flex-col">
              <textarea
                value={journalContent}
                onChange={(e) => setJournalContent(e.target.value)}
                placeholder="Write your journal entry here..."
                className="flex-1 w-full p-4 rounded-lg border focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
                disabled={isAnalyzing}
              />
              <div className="mt-4 flex justify-end">
                <button
                  type="submit"
                  disabled={isAnalyzing || !journalContent.trim()}
                  className={`px-4 py-2 rounded-lg ${
                    isAnalyzing || !journalContent.trim()
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-blue-600 hover:bg-blue-700 text-white'
                  }`}
                >
                  {isAnalyzing ? 'Analyzing...' : 'Save & Analyze'}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Journal;
