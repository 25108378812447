interface Message {
  role: 'system' | 'user' | 'assistant';
  content: string;
}

export interface ChatHistory {
  id: string;
  title: string;
  messages: Message[];
  createdAt: string;
  updatedAt: string;
}

export interface JournalEntry {
  id: string;
  content: string;
  analysis: {
    insights: string[];
    scripture: string;
    nextStep: string;
  };
  createdAt: string;
  updatedAt: string;
}

const WORKER_URL = 'https://christian-ai-worker.trottaa.workers.dev';

const SYSTEM_PROMPT = `You are a compassionate Christian AI accountability partner. Follow these guidelines:

1. Keep responses concise (2-3 sentences max)
2. Use ESV or NASB Bible translations
3. Focus on practical, actionable guidance
4. Be warm but direct
5. Ask one thoughtful question when appropriate

Remember: Brief, biblical (ESV/NASB), and practical.`;

// Load chat history from localStorage
export const loadChatHistory = (): ChatHistory[] => {
  const history = localStorage.getItem('chatHistory');
  return history ? JSON.parse(history) : [];
};

// Save chat history to localStorage
export const saveChatHistory = (history: ChatHistory[]): void => {
  localStorage.setItem('chatHistory', JSON.stringify(history));
};

// Load journal entries from localStorage
export const loadJournalEntries = (): JournalEntry[] => {
  const entries = localStorage.getItem('journalEntries');
  return entries ? JSON.parse(entries) : [];
};

// Save journal entries to localStorage
export const saveJournalEntries = (entries: JournalEntry[]): void => {
  localStorage.setItem('journalEntries', JSON.stringify(entries));
};

// Create a new journal entry
export const createJournalEntry = async (content: string): Promise<JournalEntry> => {
  const analysis = await analyzeJournalEntry(content);
  
  const newEntry: JournalEntry = {
    id: Date.now().toString(),
    content,
    analysis,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  };
  
  const entries = loadJournalEntries();
  entries.unshift(newEntry);
  saveJournalEntries(entries);
  
  return newEntry;
};

// Analyze a journal entry using the AI
export const analyzeJournalEntry = async (content: string): Promise<JournalEntry['analysis']> => {
  try {
    const response = await fetch(WORKER_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: 'journal',
        content
      }),
    });

    if (!response.ok) {
      throw new Error(`API error: ${response.status}`);
    }

    const data = await response.json();
    return data.result.response;
  } catch (error) {
    console.error('Error analyzing journal entry:', error);
    throw error;
  }
};

// Create a new chat session
export const createChatSession = (): ChatHistory => {
  const newChat: ChatHistory = {
    id: Date.now().toString(),
    title: 'New Chat',
    messages: [],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  };
  
  const history = loadChatHistory();
  history.unshift(newChat);
  saveChatHistory(history);
  
  return newChat;
};

// Update chat session
export const updateChatSession = (chatId: string, messages: Message[]): void => {
  const history = loadChatHistory();
  const chatIndex = history.findIndex(chat => chat.id === chatId);
  
  if (chatIndex !== -1) {
    history[chatIndex] = {
      ...history[chatIndex],
      messages,
      updatedAt: new Date().toISOString(),
      title: messages[1]?.content.slice(0, 30) + '...' || 'New Chat' // Use first user message as title
    };
    saveChatHistory(history);
  }
};

export const generateChatResponse = async (message: string, context: Message[] = []): Promise<string> => {
  try {
    const messages: Message[] = [
      { role: 'system', content: SYSTEM_PROMPT },
      ...context,
      { role: 'user', content: message }
    ];

    console.log('Sending request to worker:', { type: 'chat', messages });

    const response = await fetch(WORKER_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: 'chat',
        messages
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Worker response error:', response.status, errorText);
      throw new Error(`API error: ${response.status} - ${errorText}`);
    }

    const data = await response.json();
    console.log('Worker response:', data);
    return data.result.response;
  } catch (error) {
    console.error('Error in generateChatResponse:', error);
    throw error;
  }
};
